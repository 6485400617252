<template>
  <div class="intip4">
    <span v-if="useSameFontSize" class="same">
      {{ formattedNumber.head }}{{ formattedNumber.tail }} {{ title }}
    </span>
    <span v-if="!useSameFontSize" class="big">{{ formattedNumber.head }}</span>
    <span v-if="!useSameFontSize" class="small">{{ formattedNumber.tail }}</span>
    <div v-if="tooltipVal(value).showTooltip" class="initpHeadValue">
      <p class="initpHeadValueContainer" :class="[`${isCampaignPage ? 'bottomTooltipVal' : ''}`]">
        <font-awesome-icon
          v-if="tooltipVal(value).showTooltip"
          :icon="['fas', showCopiedText['field1'] ? 'check' : 'copy']"
          @click="handleCopyText(tooltipVal(value).value, 'field1')"
        />{{ tooltipVal(value).value }}
      </p>
    </div>
  </div>
</template>

<script>
import formatNumber, { formatNumberAsFullNumber } from '~/util/numeral';

export default {
  name: 'BNumero',
  props: {
    value: {
      type: Number,
      required: false,
      default: () => 0.0,
    },
    isInfinity: {
      required: false,
      type: Boolean,
      default: false,
    },
    isCampaignPage: {
      required: false,
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: () => '',
    },
    decimals: {
      type: String,
      required: false,
      default: () => '',
    },
    useSameFontSize: {
      required: false,
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data() {
    return {
      showCopiedText: {
        field1: false,
      },
    };
  },
  computed: {
    formattedNumber() {
      const vm = this;

      if (this.isInfinity) {
        return { head: 'NA' };
      }

      const frN = formatNumber(
        Math.round((Number(vm.value) + Number.EPSILON) * 100) / 100,
        vm.type,
        vm.decimals
      );
      const frNS = frN.split('');

      let head = frNS.splice(0, frNS.length - 1).join('');
      let tail = frNS.pop();

      if (!Number.isNaN(Number(tail))) {
        tail = '';
        head = frN;
      }

      return {
        head,
        tail,
      };
    },
  },
  methods: {
    async handleCopyText(text, id) {
      if (this.showCopiedText[id]) {
        return;
      }
      this.showCopiedText[id] = true;
      await navigator.clipboard.writeText(text);
      setTimeout(() => {
        this.showCopiedText[id] = false;
      }, 1000);
    },
    tooltipVal(value) {
      if (this.isInfinity) {
        return { value: 'NA', showTooltip: false };
      }
      return {
        value: formatNumberAsFullNumber(value, this.type, this.decimals),
        showTooltip: !!value,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
// Tooltip - Start
.intip4 {
  position: relative;
  top: 0;
  width: max-content;
  .initpHeadValue {
    display: flex;
    z-index: 9999;
    flex-direction: row;
    align-items: center;
  }
}
.intip4::before {
  content: '';
  top: 20px;
  font-size: 12px;
  width: 100%;
  height: 26px;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  position: absolute;
  padding-top: 8px;
  text-align: right;
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.intip4 .initpHeadValueContainer {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  width: 100px;
  padding: 10px 12px;
  margin-left: 0;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  content: attr(data-tool-tip);
  background-color: #fdea85;
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
}
.fa-check,
.fa-copy {
  color: #ffffff !important;
  text-align: center;
  position: absolute;
  padding: 3px;
  left: 7%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 999;
  font-size: 18px;
  top: 8px;
  cursor: pointer;
}

.intip4 .initpHeadValueContainer {
  padding-left: 25px;
  left: 40%;
  width: auto;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  background-color: rgb(44 48 54 / 98%);
}
.intip4:hover .initpHeadValueContainer {
  &.bottomTooltipVal {
    bottom: -78% !important;
  }
  top: -150%;
  left: 50%;
  transform: scale(1) translateX(-40%);
}
// Tooltip - End
span {
  font-weight: 300;

  &.big {
    font-size: 48px;
    font-weight: 200;
  }

  &.small {
    font-size: 33px;
    font-weight: 200;
  }
}
.cur-amt span {
  &.big {
    font-size: 48px;
  }
  &.small {
    font-size: 25px;
  }
}
.light-theme {
  span {
    font-weight: 300;

    &.big {
      font-size: 47px;
      font-weight: 200;
    }

    &.small {
      font-size: 33px;
      font-weight: 200;
    }
  }
}
</style>
